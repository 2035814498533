import React from 'react';
import { connect } from 'react-redux';
import WashComponent from '../components/wash.component';

import { getWash, clearWash, saveWash, getPlaces, getPlaceDetails, getUsers } from '../actions/washes.action';

function WashContainer(props) {
    return (
        <WashComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.WashReducer.isLoading,
        wash: state.WashReducer.wash,
        loggedUser: state.AppReducer.loggedUser
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getWash: (id) => dispatch(getWash(id)),
        clearWash: () => dispatch(clearWash()),
        saveWash: (data, id) => saveWash(data, id, dispatch),
        getPlaces: (query, token) => getPlaces(query, token),
        getPlaceDetails: (placeId) => getPlaceDetails(placeId, dispatch),
        getUsers: (query) => getUsers(query, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WashContainer);
