import _ from "lodash";
import { showToast } from '../../application/actions/app.action';
import Api from '../../application/services/api.service';
import moment from 'moment';
const namespace = 'NOTIFICATIONS';

export const SET_NOTIFICATIONS = `SET_NOTIFICATIONS_${namespace}`;
export const CLEAR_NOTIFICATIONS = `CLEAR_NOTIFICATIONS_${namespace}`;
export const SET_NOTIFICATION = `SET_NOTIFICATION_${namespace}`;
export const CLEAR_NOTIFICATION = `CLEAR_NOTIFICATION_${namespace}`;
export const IS_LOADING = `IS_LOADING_${namespace}`;
export const SET_PAGE = `SET_PAGE_${namespace}`;
export const CLEAR_PAGE = `CLEAR_PAGE_${namespace}`;
export const SET_OFFSET = `SET_OFFSET_${namespace}`;

export const setNotification = (notification) => ({
    type: SET_NOTIFICATION,
    notification
});

export const clearNotification = () => ({
    type: CLEAR_NOTIFICATION
});

export const setNotifications = (notifications, total) => ({
    type: SET_NOTIFICATIONS,
    notifications,
    total
});

export const clearNotifications = () => ({
    type: CLEAR_NOTIFICATIONS
});

export const isLoading = (isLoading) => ({
    type: IS_LOADING,
    isLoading
});

export function setOffset(offset) {
    return (dispatch) => {
        dispatch({
            type: SET_OFFSET,
            offset: offset
        });
    }
}

export function setPaginationRoute(page) {
    return (dispatch, getState) => {
        const { NotificationReducer: { limit } } = getState();

        dispatch(setOffset(limit * ((page || 1) - 1)));
        dispatch({
            type: SET_PAGE,
            page: page || 1
        });

        dispatch(getNotifications());
        window.scrollTo(0, 0);
    }
}

export function clearPaginationRoute() {
    return dispatch => {
        dispatch(setOffset(0));
        dispatch({
            type: CLEAR_PAGE
        });
        window.scrollTo(0, 0);
    }
}
export function getNotification(id) {
    return dispatch => {

        dispatch(isLoading(true));

        return Api.get(`admin/notification/${id}`, null).then((result) => {
            dispatch(isLoading(false));
            if (result.success) {
                dispatch(setNotification(result.document));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania powiadomienia'));
            }
        });
    };
}

export function getNotifications() {
    return (dispatch, getState) => {

        const { NotificationReducer: { limit, offset, queryFields, sortOptions }, AppReducer: { searchQuery, sortBy } } = getState();

        let defaultParams = {
            "limit": limit,
            "offset": offset,
            "sort[active]" : -1,
            "type" : 2

        }

        let defaultSortOption = sortOptions.filter((option) => {
            return option.default;
        })[0];

        let params = Object.assign({}, defaultParams, sortBy ? sortBy.value : defaultSortOption.value);

        if (searchQuery && queryFields) {
            queryFields.split(",").forEach((field) => {
                params[`${field}[orin]`] = searchQuery;
            });
        }

        dispatch(isLoading(true));

        return Api.get('admin/notification/', params).then((result) => {
            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(setNotifications(result.documents, result.total));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania powiadomienia'));
            }
        });

    };
}


export function saveNotification(userData, dispatch) {
    return new Promise((resolve, reject) => {

        let objData = Object.assign({}, userData);
        objData.activeUntil = moment(objData.activeUntil).format("X");

        if (objData._id) { // edycja
            return Api.put('admin/notification', objData, objData._id).then((result) => {
                console.log(result);
                dispatch(isLoading(false));
                if (result.success) {
                    dispatch(showToast('info', 'Powiadomienie zostało zaktualizowane'));
                    return resolve();
                } else if ('errors' in result) {
                    return reject(result.errors);
                }
            });
        } else { // dodawanie
            return Api.post('admin/notification', objData).then((result) => {
                dispatch(isLoading(false));
                if (result.success) {
                    dispatch(showToast('info', 'Powiadomienie zostało dodane'));
                    return resolve();
                } else if ('errors' in result) {
                    dispatch(showToast('alert', 'Błąd podczas zapisywania powiadomienia'));
                    return reject(result.errors);
                }
            });
        }
    });
}



export const changeIsActive = () => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));

        const { NotificationReducer: { notification } } = getState();
        let res = await Api.put("admin/notification", { active: !notification.active }, notification._id);
        dispatch(isLoading(false));
        if (res && res.success) {
            dispatch(showToast('info', 'Zapisano zmiany'));
            dispatch(setNotification(Object.assign({}, notification, { active: !notification.active })));
        } else {
            dispatch(showToast('alert', 'Wystąpił błąd'));
        }
    }
}

export function getUsers(query, dispatch) {
    return new Promise((resolve, reject) => {
        return Api.get('admin/user', {
            name: query,
            select: "name"
        }).then((result) => {
            if (result.success) {
                return resolve(result.documents.map((user) => { return { name: user.name, value: user._id } }));
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });
    });
}
