import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Link, useLocation, useHistory } from "react-router-dom";
import { logout } from '../actions/app.action';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faSearch } from '@fortawesome/free-solid-svg-icons';

import '../assets/css/admin-header.css';

function AdminHeaderComponent(props) {

    const { logout, setQuery } = props;
    const location = useLocation();
    const history = useHistory();

    const [addButtonRoute, setAddButtonRoute] = useState('');
    const [headerName, setHeaderName] = useState('');
    const [searchBar, showSearchBar] = useState(true);
    const [route, setRoute] = useState('');

    function handleLogout() {
        logout();
        history.push('/');
    }

    useEffect(() => {
        if (location.pathname.includes("/washes")) {
            showSearchBar(true);
            setHeaderName('Myjnie');
            setAddButtonRoute('/wash');
            setRoute('washes');
        } else if (location.pathname.includes("/users")) {
            showSearchBar(true);
            setHeaderName('Uzytkownicy');
            setAddButtonRoute('');
            setRoute('users');
        } else if (location.pathname.includes("/notifications")) {
            showSearchBar(true);
            setHeaderName('Powiadomienia');
            setAddButtonRoute('/notification');
            setRoute('notifications');
        } else {
            showSearchBar(false);
            setAddButtonRoute('');
        }
    }, [location]);

    return <div className="d-flex admin-header-wrapper">
        <div className="d-flex pl-4 pr-5 justify-content-start align-items-center">
            <h5>{headerName}</h5>
        </div>
        {searchBar && <div className="parameters-list-wrapper d-flex flex-fill flex-row">
            <div className="list-search-wrapper d-flex flex-fill justify-content-center align-items-center">
                <FontAwesomeIcon icon={faSearch} className="search-icon" color="#44503f" />
                <input type="text" name="abcd" onChange={(e) => setQuery(e.target.value, route)} className="form-control" id="login" />
            </div>
        </div>}
        <div className="d-flex flex-fill pr-4 justify-content-end align-items-center">
            {addButtonRoute !== '' && <Link className="btn btn-primary" to={addButtonRoute}>
                <span>Dodaj</span>
            </Link>}
            <FontAwesomeIcon onClick={() => handleLogout()} icon={faSignInAlt} color="#252525" className="logout-icon" />
        </div>
    </div>
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout())
    }
};

export default connect(null, mapDispatchToProps)(AdminHeaderComponent);