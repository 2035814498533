import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';


function PaginationComponent({ totalCount, paginationRoute, route, setPaginationRoute, limit }) {

  const [pagesCount, setPagesCount] = useState(0);

  const arrowLeft = <svg height="10px" viewBox="0 0 15 26" width="10px"><polygon fill="white" points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 2.049,15.584 -0.035,13.5 " /></svg>;
  const arrowRight = <svg height="10px" viewBox="0 0 15 26" width="10px"><polygon fill="white" points="2.019,0.58 -0.035,2.634 10.646,13.316 -0.035,23.997 2.019,26.052 14.755,13.316 " /></svg>;

  useEffect(() => {
    setPagesCount(Math.ceil(totalCount / limit))
  }, [totalCount, limit])

  if (pagesCount < paginationRoute) {
    return null;
  }

  // środkowe routy
  function getRoutes() {
    const firstRoute = paginationRoute - 1;
    const secoundRoute = paginationRoute;
    const thirdRoute = paginationRoute + 1;

    return <div className="routes">
      {paginationRoute > 1 && <Link to={`${route}/page/${firstRoute}`} className="single-route" onClick={() => setPaginationRoute(firstRoute)}>{firstRoute}</Link>}
      <span className="single-route active" onClick={() => setPaginationRoute(secoundRoute)}>{secoundRoute}</span>
      {thirdRoute < pagesCount && <Link to={`${route}/page/${thirdRoute}`} className="single-route" onClick={() => setPaginationRoute(thirdRoute)}>{thirdRoute}</Link>}
    </div>
  }

  return <Fragment>
    <div className="pagination-wrapper d-flex justify-content-end">
      <div className="pagination-navigation">
        {paginationRoute !== 1 && <Link className="pag-go-prev" to={`${route}/page/${paginationRoute - 1}`} onClick={() => setPaginationRoute(paginationRoute - 1)}>
          {arrowLeft}
        </Link>}
        <div className="pagination-routes">
          {paginationRoute > 2 && <Link className="first-route" to={`${route}/page/${1}`} onClick={() => setPaginationRoute(1)}>
            <span className="single-route">1</span>
          </Link>}
          {pagesCount > 3 && paginationRoute !== 2 && paginationRoute !== 1 && <div className="route-breaker">
            <span>...</span>
          </div>}
          {getRoutes()}
          {pagesCount > 3 && paginationRoute !== (pagesCount - 1) && paginationRoute !== pagesCount && <div className="route-breaker">
            <span>...</span>
          </div >}
          {paginationRoute < pagesCount && <Link className="last-route" to={`${route}/page/${pagesCount}`} onClick={() => setPaginationRoute(pagesCount)}>
            <span className={`single-route ${paginationRoute === pagesCount ? "active" : ""}`} >{pagesCount}</span>
          </Link>}
        </div>
        {paginationRoute !== pagesCount && <Link className="pag-go-next" to={`${route}/page/${paginationRoute + 1}`} onClick={() => setPaginationRoute(paginationRoute + 1)}>
          {arrowRight}
        </Link>}
      </div>
    </div>
  </Fragment>
}

export default PaginationComponent;