import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import List from '../../application/components/list.component';
import WashListCard from './washListCard.component';

function WashesComponent(props) {

  const { isLoading, washes, getWashes, clearWashes, total, limit, paginationRoute, setPaginationRoute, clearPaginationRoute, clearQuery, sortOptions } = props;

  let { page } = useParams();

  useEffect(() => {
    getWashes();
    return () => {
      clearPaginationRoute();
      clearWashes();
      clearQuery();
    }
  }, [getWashes, clearWashes, clearPaginationRoute, clearQuery]);

  useEffect(() => {
    if (page) {
      setPaginationRoute(page || 1);
    }
  }, [page, setPaginationRoute]);

  return <div className="d-flex flex-fill">
    <List
      data={washes}
      Card={WashListCard}
      isLoading={isLoading}
      total={total}
      paginationRoute={parseInt(paginationRoute)}
      limit={limit}
      setPaginationRoute={setPaginationRoute}
      route="/washes"
      sortOptions={sortOptions}
    />
  </div>
}

export default WashesComponent;