import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import AppLoader from '../../application/components/loader.component';
import ModalComponent from '../../application/components/modal.component';

function NotificationDetailsComponent(props) {

    let { id } = useParams();
    const { getNotification, clearNotification, notification, isLoading, changeIsActive} = props;
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    useEffect(() => {
        if (id) {
            getNotification(id);
        }
        return () => {
            clearNotification();
        }
    }, [id, getNotification, clearNotification,]);


    function toggleConfirmationModal() {
        setShowConfirmationModal(!showConfirmationModal);
    }

    function handleChangeIsActive() {
        toggleConfirmationModal(); // zamykamy modal
        changeIsActive();
    }
  return <div className="user-wrapper flex-column d-flex flex-fill">
        
        {isLoading && <AppLoader type="absolute" />}
        {notification && <div>
            <div className="save-wrapper d-flex justify-content-between align-items-center">
                <h4>Szczegóły powiadomienia</h4>
                <div className="save-button ">
                    <Link to={`/notification/${id}`} className="btn btn-dark mr-2">EDYTUJ</Link>
                    <button type="button" className="btn btn-primary" onClick={() => toggleConfirmationModal()}>{notification.active ? "DEAKTYWUJ" : "AKTYWUJ"}</button>
                </div>
            </div>
            <div className="media">
                <div className="media-body">
                    <div className="notification-detail">
                        <label>Data utworzenia</label>
                        <p>{moment(notification.createdAt).format("DD.MM.YYYY HH:mm")}</p>
                    </div>
                    <div className="notification-detail">
                        <label>Powiadomienie</label>
                        <p>{notification.text}</p>
                    </div>
                    <div className="notification-detail">
                        <label>Aktywne do</label>
                        <p>{moment(notification.activeUntil).format("DD.MM.YYYY HH:mm")}</p>
                    </div>
                    
                </div>
            </div>
        </div>}
        <ModalComponent
            show={showConfirmationModal}
            handleSubmit={handleChangeIsActive}
            handleToggleModal={toggleConfirmationModal}
            contentText={`Czy na pewno chcesz ${notification.active ? "deaktywować" : "aktywować"} powiadomienie?`}
            submitText={notification.active ? "Deaktywuj" : "Aktywuj"} />
    </div>
}

export default NotificationDetailsComponent;

