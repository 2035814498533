import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import logoWASHIT from '../assets/img/logo_v2.png';
import loginBackground from "../assets/img/login-background.jpg";
import '../assets/css/login.css';

function LoginComponent(props) {

  const { isAuth } = props;
  let history = useHistory();

  if(isAuth) {
    return null;
  }

  const LoginForm = () => {

    const [errors, setApiErrors] = useState(null);
    const formik = useFormik({
      initialValues: { email: '', password: '' },
      validationSchema: Yup.object({
        email: Yup.string().required("Wartość jest wymagana"),
        password: Yup.string().required("Wartość jest wymagana")
      }),
      onSubmit: (values) => {
        props.loginIn(values).then(() => {
          history.push('/users');
        }, (errors) => {
          setApiErrors(errors);
          formik.setSubmitting(false)
        });
      }
    });

    return (<form onSubmit={formik.handleSubmit}>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          placeholder="Email"
          className={`form-control ${(formik.errors && formik.touched.email && formik.errors.email) || (errors && errors.email) ? 'is-error' : ''} input-dark`}
          id="email"
          autoComplete="off"
        />
        {formik.errors.email && formik.touched.email ? (
          <div className="error-warpper">{formik.errors.email}</div>
        ) : null}
        {errors && errors.email ? (
          <div className="error-warpper">{errors.email}</div>
        ) : null}
      </div>
      <div className="form-group">
        <label htmlFor="password">Hasło</label>
        <input
          autoComplete="new-password"
          type="password"
          name="password"
          placeholder="Hasło"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          className={`form-control ${(formik.errors && formik.touched.password && formik.errors.password) || (errors && errors.password) ? 'is-error' : ''} input-dark`}
          id="password"
        />
        {formik.errors.password && formik.touched.password ? (
          <div className="error-warpper">{formik.errors.password}</div>
        ) : null}
        {errors && errors.password ? (
          <div className="error-warpper">{errors.password}</div>
        ) : null}
      </div>
      <div className="form-group mt-5">
        <button type="submit" disabled={formik.isSubmitting} className="login-button btn btn-primary">ZALOGUJ</button>
      </div>
    </form>);
  };

  return <div className="container-fluid login-form-wrapper d-flex justify-content-center align-items-center">
    <div className="login-form">
      <img src={logoWASHIT} alt="Logo" width={250}/>
      <LoginForm />
    </div>
  </div>
}

export default LoginComponent;
