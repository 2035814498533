import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import '../assets/css/admin-sidebar.css';
import logoCloud from '../assets/img/logo-cloud.png';
import washItLogo from '../assets/img/logo.png';

import { getReport } from '../actions/app.action';

function AdminSidebarComponent(props) {
    const { loggedUser } = props;
    return <div className="admin-sidebar-wrapper h-100">
        <div className="sidebar-logo d-flex">
            <img alt="app-logo" className="img-fluid" src={washItLogo} />
        </div>
        <div className="nav-menu mt-4">
            {loggedUser.type === "admin" && <Link className="d-flex menu-row" to="/users">
                <div className="text-center align-items-center d-flex flex-fill menu-row-name">Użytkownicy</div>
            </Link>}
            <Link className="d-flex menu-row" to="/washes">
                <div className="text-center align-items-center d-flex flex-fill menu-row-name">Myjnie</div>
            </Link>
            
            <Link className="d-flex menu-row" to="/notifications">
                <div className="text-center align-items-center d-flex flex-fill menu-row-name">Powiadomienia</div>
            </Link>
            <Link className="d-flex menu-row" to="/profile">
                <div className="text-center align-items-center d-flex flex-fill menu-row-name">Profil</div>
            </Link>




        </div>
        <div className="powered-by-sidebar">
            <img alt="Powered By Over Cloud" height="40px" src={logoCloud} />
        </div>
    </div>

}

const mapDispatchToProps = dispatch => {
    return {
        getReport: () => dispatch(getReport())
    }
};

export default connect(null, mapDispatchToProps)(AdminSidebarComponent);