import React from "react";
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom";

import { checkIsAuth, setQuery } from '../actions/app.action';
import AppLoader from '../components/loader.component';

import LoginContainer from '../containers/login.container';
import UsersContainer from '../../users/containers/users.container';
import UserDetailsContainer from '../../users/containers/userDetails.container';
import WashesContainer from '../../washes/containers/washes.container';
import WashContainer from "../../washes/containers/wash.container";
import WashDetailsContainer from '../../washes/containers/washDetails.container';
import ProfileContainer from '../../profile/containers/profile.container';
import AdminSidebarComponent from "../components/adminSidebar.component";
import AdminHeaderComponent from "../components/adminHeader.component";

import NotificationsContainer from '../../notifications/containers/notifications.container';
import NotificationContainer from '../../notifications/containers/notification.container';
import NotificationDetailsContainer from '../../notifications/containers/notificationDetails.container';


import ToastComponent from "../components/toast.component";

function App(props) {

  const { appIsLoading, isAuth, setQuery, loggedUser } = props;
  return (
    <div className="d-flex">
      {appIsLoading && <AppLoader type="absolute" />}
      <Router>
        {isAuth && <div className="d-flex">
          <AdminSidebarComponent loggedUser={loggedUser} />
        </div>}
        <div className="d-flex flex-fill flex-column">
          {isAuth && <div className="d-flex">
            <AdminHeaderComponent setQuery={setQuery} />
          </div>}
          <div className={`d-flex flex-fill admin-right-wrapper ${!isAuth ? "no-auth" : ""}`}>
            <Route exact path={["/", "/login"]} >
              <LoginContainer />
            </Route>
            <AdminRoute {...props} exact path={["/users/page/:page?", "/users"]}>
              <UsersContainer />
            </AdminRoute>
            <AdminRoute {...props} path={["/user/:id"]}>
              <UserDetailsContainer />
            </AdminRoute>
            <PrivateRoute {...props} exact path={["/washes/page/:page?", "/washes"]}>
              <WashesContainer />
            </PrivateRoute>
            <PrivateRoute {...props} exact path="/washes/:id">
              <WashDetailsContainer />
            </PrivateRoute>
            <PrivateRoute {...props}  path={["/wash/:id?", "/wash"]}>
              <WashContainer />
            </PrivateRoute>
            <PrivateRoute {...props} exact path={["/notifications/page/:page?", "/notifications"]}>
              <NotificationsContainer />
            </PrivateRoute>
          
            <PrivateRoute {...props} exact path="/notifications/:id">
              <NotificationDetailsContainer />
            </PrivateRoute>
              
            <PrivateRoute {...props}  path={["/notification/:id?", "/notification"]}>
              <NotificationContainer />
            </PrivateRoute> 
            <PrivateRoute {...props} path="/profile">
              <ProfileContainer />
            </PrivateRoute>

            
           

          </div>
          <ToastComponent />

        </div>
      </Router>
    </div>
  );

  function PrivateRoute({ children, appIsLoading, isAuth, loggedUser, ...rest }) {
    let isAllowed = appIsLoading || (isAuth && loggedUser && (loggedUser.type === "admin" || loggedUser.type === "freelancer"));
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAllowed ? (
            children
          ) : (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location }
                }}
              />
            )
        }
      />
    );
  }

  function AdminRoute({ children, appIsLoading, isAuth, loggedUser, ...rest }) {
    let isAllowed = appIsLoading || (isAuth && loggedUser && loggedUser.type === "admin");
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAllowed ? (
            children
          ) : (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location }
                }}
              />
            )
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  appIsLoading: state.AppReducer.appIsLoading,
  isAuth: state.AppReducer.isAuth,
  loggedUser: state.AppReducer.loggedUser
});

const mapDispatchToProps = (dispatch) => {
  return {
    checkIsAuth: () => dispatch(checkIsAuth()),
    setQuery: (value, route) => dispatch(setQuery(value, route))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
