import React from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import _ from "lodash";

import '../assets/css/washCard.css';
moment.locale("pl");

const statusNames = {
  "1": "Oczekuje na akceptację",
  "2": "Odrzucona",
  "3": "Zaakceptowana"
}

function getAddress(address) {
  if(_.size(address) == 1 && address.country) {
    return "Brak uzupełnionego adresu";
  } 
  return `${address.postCode? address.postCode : ''} ${address.locality? address.locality : ''} ${address.street? ', ul.' + address.street : ''} ${address.streetNumber? address.streetNumber : ''}`;
}

function WashListCardComponent({ index, record }) {
  let formattedAddress = getAddress(record.address);
  return <div className={`d-flex list-card-wrapper ${!record.active ? "no-active" : ""} flex-row`}>
    <Link className="d-flex flex-fill" to={`/washes/${record._id}`}>
      <div className="card-section card-label-wrapper justify-content-center w-25 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Nazwa</div>
        <div className="card-label-value">{record.name}</div>
      </div>
      <div className="card-section card-label-wrapper justify-content-center w-25 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Adres</div>
        <div className="card-label-value">{formattedAddress}</div>
      </div>
      <div className="card-section card-label-wrapper  justify-content-center w-25 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Status</div>
        <div className="card-label-value">{statusNames[record.status]}</div>
      </div>
    </Link>
  </div>
}

export default WashListCardComponent;