import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import AppLoader from '../../application/components/loader.component';
import ModalComponent from '../../application/components/modal.component';
import Api from "../../application/services/api.service";
import StatusChangeModal from './statusChangeModal.component';
import '../assets/css/wash.css';
import WashPaymentCard from "./washPaymentCard.component";
import List from '../../application/components/list.component';
import featuresDicts from "../assets/featuresDicts";
import PhotoModal from '../../application/components/photoModal.component';


const typeLabels = {
    "1": "automatyczna",
    "2": "bezdotykowa",
    "3": "ręczna"
}

const statusNames = {
    "1": "Oczekuje na akceptację",
    "2": "Odrzucona",
    "3": "Zaakceptowana"
}

function WashDetailsComponent(props) {

    let { id } = useParams();
    const { getWash, clearWash, wash, isLoading, changeIsActive, changeStatus, loggedUser } = props;
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [photoForViewing, setPhotoForViewing] = useState(false);

    useEffect(() => {
        if (id) {
            getWash(id);
        }
        return () => {
            clearWash();
        }
    }, [id, getWash, clearWash,]);

    function toggleShowPhotoModal() {
        setShowPhotoModal(!showPhotoModal);
    }

    function toggleConfirmationModal() {
        setShowConfirmationModal(!showConfirmationModal);
    }

    function handleChangeIsActive() {
        toggleConfirmationModal(); // zamykamy modal
        changeIsActive();
    }

    function toggleStatusChangeModal() {
        setShowStatusChangeModal(!showStatusChangeModal);
    }

    function handlePhotoClick(photo) {
        setPhotoForViewing(photo);
        toggleShowPhotoModal();
    }

    function renderGallery() {
        const { wash } = props;
        if (!_.isEmpty(wash.gallery)) {
            let photos = wash.gallery.map((file, index) => {
                return <img className="mr-1 wash-gallery-photo mt-1" src={Api.getHost() + file} alt="Zdjęcie" key={index} onClick={() => handlePhotoClick(Api.getHost() + file)} />
            });
            return <div className="gallery">{photos}</div>
        }
        return null;
    }

    function renderPayments() {
        const { wash } = props;
        if (_.isArray(wash.payments)) {
            return <List
                data={wash.payments}
                Card={WashPaymentCard}
                isLoading={false}
                total={wash.payments.length}
                paginationRoute={1}
                limit={100}
                setPaginationRoute={() => null}
                route="/washes"
                title="Płatności"
                hidePagination={true}
            />
        }
        return null;
    }

    return <div className="user-wrapper flex-column d-flex flex-fill">
        <PhotoModal
            show={showPhotoModal}
            handleToggleModal={() => toggleShowPhotoModal()}
            photo={photoForViewing}
        />
        {isLoading && <AppLoader type="absolute" />}
        {wash && <div>
            <div className="save-wrapper d-flex justify-content-between align-items-center">
                <h4>Szczegóły myjni</h4>
                <div className="save-button ">
                    <Link to={`/wash/${id}`} className="btn btn-dark mr-2">EDYTUJ</Link>
                    {loggedUser.type === "admin" && <button type="button" className="btn btn-primary mr-2" onClick={() => toggleStatusChangeModal()}>ZMIEŃ STATUS</button>}
                    <button type="button" className="btn btn-primary" onClick={() => toggleConfirmationModal()}>{wash.active ? "DEAKTYWUJ" : "AKTYWUJ"}</button>
                </div>
            </div>
            <div className="media">
                {wash.avatar && <img className="d-flex mr-3 wash-avatar" src={Api.getHost() + wash.avatar} alt="Logo myjni" />}
                <div className="media-body">
                    <div className="wash-detail">
                        <h5 className="mt-0"><b>{wash.name}</b></h5>
                        <p>{`Myjnia ${typeLabels[_.get(wash, "types[0]")]}`}</p>
                        {wash.hasOwnProperty("points") && <p className="text-success">Przyznane punkty: {wash.points}</p>}
                    </div>
                    <div className="wash-detail">
                        <label>Data utworzenia</label>
                        <p>{moment(wash.createdAt).format("DD.MM.YYYY HH:mm")}</p>
                    </div>
                    <div className="wash-detail">
                        <label>Dodana przez</label>
                        <p>{_.get(wash, "creator.name", "-")}</p>
                    </div>
                    <div className="wash-detail">
                        <label>Właściciel</label>
                        <p>{_.get(wash, "owner.name", "-")}</p>
                    </div>
                    <div className="wash-detail">
                        <label>Status</label>
                        <p>{statusNames[wash.status]}</p>
                    </div>
                    <div className="wash-detail">
                        <label>Stanowiska</label>
                        <p>{wash.stands}</p>
                    </div>
                    <div className="wash-detail">
                        <label>Dostępne opcje</label>
                        <p>{wash.features && _.join(wash.features.map((feature) => featuresDicts[feature]), ", ")}</p>
                    </div>
                    {wash.address && <address>
                        <strong>Adres</strong><br />
                        {`${wash.address.postCode || '(brak kodu pocztowego)'}, ${wash.address.locality || '(brak miasta)'}`}<br />
                        {`ul. ${wash.address.street || '(brak ulicy)'} ${wash.address.streetNumber || '(brak numeru ulicy)'}`}<br />
                        {`woj. ${wash.address.area_1 || "(brak województwa)"}, powiat ${wash.address.area_2 || "(brak powiatu)"}, gmina ${wash.address.area_3 || "(brak gminy)"}`}<br />
                        {wash.location && <span>Koordynaty: {_.join(wash.location.coordinates, ", ")}</span>}
                    </address>}
                </div>
            </div>
            {renderGallery()}
            {wash.payments && renderPayments()}
        </div>}
        <ModalComponent
            show={showConfirmationModal}
            handleSubmit={handleChangeIsActive}
            handleToggleModal={toggleConfirmationModal}
            contentText={`Czy na pewno chcesz ${wash.active ? "deaktywować" : "aktywować"} myjnię?`}
            submitText={wash.active ? "Deaktywuj" : "Aktywuj"} />
        {wash && wash.status && <StatusChangeModal show={showStatusChangeModal} handleChangeStatus={changeStatus} initialStatus={wash.status} handleToggleModal={toggleStatusChangeModal} isLoading={isLoading} washId={wash._id} />}
    </div>
}

export default WashDetailsComponent;

