import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { setSortBy } from '../actions/app.action';

function SortComponent(props) {

    const { setSortBy, sortOptions, route, sortyBy } = props;

    let defaultSelectedOption = sortOptions.filter((option) => {
        return option.default;
    })[0];

    return (
        <div className="sort-wrapper">
            <Select
                placeholder="Sortuj po"
                name="sort-by-type"
                id="sort-by-type"
                onChange={(value) => { setSortBy(value, route) }}
                value={sortyBy || defaultSelectedOption}
                options={sortOptions}
                className="basic-multi-select"
                classNamePrefix="select"
            />
        </div>
    )
}

SortComponent.propTypes = {
    sortOptions: PropTypes.array.isRequired,
    route: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    sortyBy: state.AppReducer.sortBy
});

const mapDispatchToProps = (dispatch) => {
    return {
        setSortBy: (sortByOption, route) => dispatch(setSortBy(sortByOption, route))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SortComponent);


