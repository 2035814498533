import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import * as Yup from 'yup';
import _ from "lodash";
import Api from "../../application/services/api.service";
import Autocomplete from "../../application/components/autcomplete.component";
import AppLoader from '../../application/components/loader.component';
import featuresDicts from "../assets/featuresDicts";
import '../assets/css/wash.css';

const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = "image/jpg, image/jpeg, image/gif, image/png";


function WashComponent(props) {

    let { id } = useParams();
    let history = useHistory();
    const [washAddress, setWashAddress] = useState([]);
    const [washOwner, setWashOwner] = useState([]);
    const [washAvatar, setWashAvatar] = useState([]);

    const { getWash, clearWash, wash, isLoading, saveWash, getPlaces, getPlaceDetails, getUsers, loggedUser } = props;

    useEffect(() => {
        if (id) {
            getWash(id);
        }
        return () => {
            clearWash();
        }
    }, [id, getWash, clearWash]);

    const formData = useCallback(setFormData, [wash]);
    useEffect(() => { // jak przyjdzie notifikacja w propsach to ustawiamy go na formularzu
        formData();
    }, [wash, formData]);

    const WASH_TYPES = [
        { value: "1", name: "Automatyczna" },
        { value: "2", name: "Bezdotykowa" },
        { value: "3", name: "Ręczna" }
    ]

    function setFormData() {
        Object.keys(wash).map((name) => {
            if (name === "location") {
                formik.setFieldValue('coordinates', wash.location.coordinates);
            } else if (name === "owner") {
                formik.setFieldValue(name, { name: wash.owner.name, value: wash.owner._id });
            } else {
                formik.setFieldValue(name, wash[name]);
            }
            return true;
        });
    }

    const [errors, setApiErrors] = useState(null);
    const formik = useFormik({
        initialValues: {
            owner: '',
            active: false,
            name: '',
            types: [],
            stands: 0,
            features: [],
            address: {},
            coordinates: []
        },
        validationSchema: Yup.object({
            owner: Yup.object().nullable(),
            active: Yup.boolean().required("Wartość jest wymagana"),
            name: Yup.string().required("Wartość jest wymagana"),
            types: Yup.array().required("Wartość jest wymagana").min(1, "Wartość jest wymagana"),
            features: Yup.array(),
            address: Yup.object().shape({
                postCode: Yup.string(),
                country: Yup.string(),
                locality: Yup.string(),
                street: Yup.string(),
                streetNumber: Yup.string(),
                area_1: Yup.string(),
                area_2: Yup.string(),
                area_3: Yup.string()
            }),
            stands: Yup.number().required("Wartość jest wymagana"),
            coordinates: Yup.array(),
            avatar: Yup.mixed(),
            gallery: Yup.mixed(),
            galleryFiles: Yup.mixed()
        }),
        onSubmit: (values) => {
            setApiErrors(null);
            saveWash(values, id).then(() => {
                history.push('/washes');
            }, (errors) => {
                setApiErrors(errors);
                formik.setSubmitting(false);
            });
        }
    });

    function handleFeatureChange(feature) {
        let index = formik.values.features.indexOf(feature);
        if (index > -1) {
            formik.setFieldValue("features", _.without(formik.values.features, feature));
        } else {
            formik.setFieldValue("features", _.concat(formik.values.features, [feature]));
        }
    }

    //
    function renderFeatures() {
        return Object.keys(featuresDicts).map((feature, index) => {
            return (
                <div className="form-group col-md-12 col-lg-6 mb-0" key={`feature${index}`}>
                    <div className="form-group custom-control custom-checkbox mt-1 mb-0">
                        <input
                            id={feature}
                            className="custom-control-input"
                            name={feature}
                            type="checkbox"
                            onChange={() => handleFeatureChange(feature)}
                            checked={formik.values.features.includes(feature)}
                            value={feature}
                        />
                        <label className="custom-control-label" htmlFor={feature}>{featuresDicts[feature]}</label>
                    </div>
                </div>)
        });
    }

    function handleTypesChange(type) {
        let index = formik.values.types.indexOf(type);
        if (index > -1) {
            formik.setFieldValue("types", _.without(formik.values.types, type));
        } else {
            formik.setFieldValue("types", _.concat(formik.values.types, [type]));
        }
    }

    function renderTypes() {
        return WASH_TYPES.map((type, index) => {
            return (
                <div className="form-group col-md-12 col-lg-6" key={`type${index}`}>
                    <div className="form-group custom-control custom-checkbox mt-1 mb-0">
                        <input
                            id={type.name}
                            className="custom-control-input"
                            name={type.name}
                            type="checkbox"
                            onChange={() => handleTypesChange(type.value)}
                            checked={formik.values.types.includes(type.value)}
                            value={type.value}
                        />
                        <label className="custom-control-label" htmlFor={type.name}>{type.name}</label>
                    </div>
                </div>)
        });
    }



    function handleFocusInputs() {
        let materialInput = document.querySelectorAll('.material-input');

        if (materialInput) {
            materialInput.forEach(function (e) {
                let input = e.querySelector('.material-input__input');
                let label = e.querySelector('label');

                if (input && label) {
                    input.addEventListener('focusin', (event) => {
                        label.classList.add('float-label');
                    })

                    input.addEventListener('focusout', (event) => {
                        if (input.value === "") {
                            label.classList.remove('float-label');
                        }
                    })
                }

            })
        }
    }

    function handleAddressSelect(selected) {
        if (selected instanceof Array && selected[0]) {
            getPlaceDetails(selected[0].value).then((data) => {
                formik.setFieldValue("address", _.omit(data, "coordinates"));
                formik.setFieldValue("coordinates", data.coordinates);
            });
        }
    }

    function handleOwnerSelect(selected) {
        if (selected instanceof Array && selected[0]) {
            getPlaceDetails(selected[0].value).then((data) => {
                formik.setFieldValue("owner", selected[0]);
            });
        }
    }

    function handleAvatarUpload(e) {
        if (_.get(e, "target.files[0]")) {
            formik.setFieldValue("avatar", e.target.files[0]);
        }
    }

    function renderGallery() {
        if (!_.isEmpty(formik.values.gallery)) {
            let photos = formik.values.gallery.map((file, index) => {
                return <img className="mr-1 wash-gallery-photo mt-1" src={Api.getHost() + file} alt="Zdjęcie" key={index} title="Kliknij aby usunać" onClick={() => handleRemoveFile(index)} />
            });
            return <div className="gallery">{photos}</div>
        }
        return null;
    }

    function handleGalleryUpload(e) {
        if (_.get(e, "target.files[0]")) {
            formik.setFieldValue("galleryFiles", e.target.files);
        }
    }

    function handleRemoveFile(index) {
        formik.setFieldValue("gallery", _.without(formik.values.gallery, _.nth(formik.values.gallery, index)));
    }

    return <div className="wash-wrapper flex-column d-flex flex-fill">
        {isLoading && <AppLoader type="absolute" />}
        <form onSubmit={formik.handleSubmit}>
            <div className="save-wrapper d-flex justify-content-between align-items-center">
                <h4>{id ? "Edycja" : "Dodawanie"} myjni</h4>
                <div className="save-button ">
                    <button type="submit" className="btn btn-primary mr-2">ZAPISZ</button>
                    <Link className="btn btn-dark" to="/washes">ANULUJ</Link>
                </div>
            </div>
            <div className="d-flex flex-fill">
                <div className="flex-fill">
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            {wash.avatar && <div className="mb-2"><img className="img-fluid wash-avatar" src={Api.getHost() + wash.avatar} alt="Logo myjni" /><br /></div>}
                            <label htmlFor="title">{wash.avatar ? "Zmień avatar" : "Avatar"}</label>
                            <input
                                autoComplete="avatar"
                                type="file"
                                className={`form-control ${(formik.errors && formik.touched.avatar && formik.errors.avatar) || (errors && errors.avatar) ? 'is-error' : ''}`}
                                onChange={handleAvatarUpload}
                                name="avatar"
                                id="avatar"
                                accept={SUPPORTED_FORMATS}
                            />
                            {formik.errors.avatar && formik.touched.avatar ? (
                                <div className="error-warpper">{formik.errors.avatar}</div>
                            ) : null}
                            {errors && errors.avatar ? (
                                <div className="error-warpper">{errors.avatar}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6">
                            <label htmlFor="title">Nazwa</label>
                            <input
                                autoComplete="name"
                                type="text"
                                className={`form-control ${(formik.errors && formik.touched.name && formik.errors.name) || (errors && errors.name) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                name="name"
                                id="name"
                            />
                            {formik.errors.name && formik.touched.name ? (
                                <div className="error-warpper">{formik.errors.name}</div>
                            ) : null}
                            {errors && errors.name ? (
                                <div className="error-warpper">{errors.name}</div>
                            ) : null}
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12 text-center mb-3"><h5>Typ</h5></div>
                        {renderTypes()}
                        <div className="col-12 text-center">
                            {formik.errors.types && formik.touched.types ? (
                                <div className="error-warpper">{formik.errors.types}</div>
                            ) : null}
                            {errors && errors.types ? (
                                <div className="error-warpper">{errors.types}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6 mb-0">
                            <label htmlFor="login">Ilość stanowisk</label>
                            <input
                                autoComplete="stands"
                                type="number"
                                className={`form-control ${(formik.errors && formik.touched.stands && formik.errors.stands) || (errors && errors.stands) ? 'is-error' : ''}`}
                                onChange={(e) => formik.setFieldValue("stands", e.target.value)}
                                value={formik.values.stands}
                                name="stands"
                                id="stands"
                                step="1"
                            />
                            {formik.errors.stands && formik.touched.stands ? (
                                <div className="error-warpper">{formik.errors.stands}</div>
                            ) : null}
                            {errors && errors.stands ? (
                                <div className="error-warpper">{errors.stands}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12 col-lg-6 mb-1 mt-3">
                            <label>Adres</label>
                            <Autocomplete
                                state={washAddress}
                                setState={setWashAddress}
                                options={[]}
                                multiple={false}
                                getOptionsMethod={getPlaces}
                                value={formik.values.address.city}
                                handleOnSelect={handleAddressSelect}
                                placeholder="Ulica nr.budynku, Miasto"
                                isRequired={true}
                                handleFocusInputs={() => handleFocusInputs()}
                            />
                            {!_.isEmpty(formik.values.address) && <address>
                                <strong>Wybrany adres</strong><br />
                                {`${formik.values.address.postCode}, ${formik.values.address.locality}`}<br />
                                {`ul. ${formik.values.address.street} ${formik.values.address.streetNumber}`}<br />
                                {`woj. ${formik.values.address.area_1 || "-"}, powiat ${formik.values.address.area_2 || "-"}, gmina ${formik.values.address.area_3 || "-"}`}<br />
                                {formik.values.coordinates && <span>Koordynaty: {_.join(formik.values.coordinates, ", ")}</span>}
                            </address>}
                            {_.isEmpty(formik.values.address) && <strong>Nie wybrano adresu</strong>}

                            {formik.errors.address && formik.touched.address ? (
                                <div>
                                    {formik.errors.address.postCode && <div className="error-warpper">{formik.errors.address.postCode}</div>}
                                    {formik.errors.address.country && <div className="error-warpper">{formik.errors.address.country}</div>}
                                    {formik.errors.address.locality && <div className="error-warpper">{formik.errors.address.locality}</div>}
                                    {formik.errors.address.street && <div className="error-warpper">{formik.errors.address.street}</div>}
                                    {formik.errors.address.streetNumber && <div className="error-warpper">{formik.errors.address.streetNumber}</div>}
                                    {formik.errors.address.area_1 && <div className="error-warpper">{formik.errors.address.area_1}</div>}
                                    {formik.errors.address.area_2 && <div className="error-warpper">{formik.errors.address.area_2}</div>}
                                    {formik.errors.address.coordinates && <div className="error-warpper">{formik.errors.coordinates}</div>}
                                </div>
                            ) : null}
                            {errors && errors.address ? (
                                <div>
                                    <div className="error-warpper">{errors.address.postCode}</div>
                                    <div className="error-warpper">{errors.address.country}</div>
                                    <div className="error-warpper">{errors.address.locality}</div>
                                    <div className="error-warpper">{errors.address.street}</div>
                                    <div className="error-warpper">{errors.address.streetNumber}</div>
                                    <div className="error-warpper">{errors.address.area_1}</div>
                                    <div className="error-warpper">{errors.address.area_2}</div>
                                    <div className="error-warpper">{errors.coordinates}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {loggedUser.type === "admin" && <div className="row">
                        <div className="form-group col-md-12 col-lg-6 mb-1 mt-3">
                            <label>Właściciel</label>
                            <Autocomplete
                                state={washOwner}
                                setState={setWashOwner}
                                options={[]}
                                multiple={false}
                                getOptionsMethod={getUsers}
                                value={formik.values.address.owner}
                                handleOnSelect={handleOwnerSelect}
                                placeholder="Wpisz nazwę właściciela"
                                isRequired={true}
                                handleFocusInputs={() => handleFocusInputs()}
                            />
                            <div>
                                <strong>Wybrany właściciel</strong><br />
                                {formik.values.owner ? formik.values.owner.name : "Nie wybrano"}
                            </div>
                            {formik.errors.owner && formik.touched.owner ? (
                                <div className="error-warpper">{formik.errors.owner}</div>
                            ) : null}
                            {errors && errors.owner ? (
                                <div className="error-warpper">{errors.owner}</div>
                            ) : null}
                        </div>
                    </div>}
                    <div className="row mt-3">
                        <div className="col-12 text-center mb-3"><h5>Opcje</h5></div>
                        {renderFeatures()}
                        <div className="col-12 text-center">
                            {formik.errors.features && formik.touched.features ? (
                                <div className="error-warpper">{formik.errors.features}</div>
                            ) : null}
                            {errors && errors.features ? (
                                <div className="error-warpper">{errors.features}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-center mb-3"><h5>Galeria</h5></div>
                        {renderGallery()}
                        <br />
                        <div className="col-12 form-group text-center mb-3 mt-3">
                            <label htmlFor="galleryFiles">Dodaj zdjęcia</label>
                            <input
                                autoComplete="galleryFiles"
                                type="file"
                                multiple={true}
                                className={`form-control ${(formik.errors && formik.touched.gallery && formik.errors.gallery) || (errors && errors.gallery) ? 'is-error' : ''}`}
                                onChange={handleGalleryUpload}
                                name="galleryFiles"
                                id="galleryFiles"
                                accept={SUPPORTED_FORMATS}
                            />
                        </div>
                        <div className="col-12 text-center">
                            {formik.errors.gallery && formik.touched.gallery ? (
                                <div className="error-warpper">{formik.errors.gallery}</div>
                            ) : null}
                            {errors && errors.gallery ? (
                                <div className="error-warpper">{errors.gallery}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div >
}

export default WashComponent;