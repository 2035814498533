import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import AppLoader from '../../application/components/loader.component';
import ModalComponent from '../../application/components/modal.component';

function StatusChangeModal(props) {
    const { show, handleChangeStatus, handleToggleModal, initialStatus, isLoading, washId } = props;
    const [errors, setApiErrors] = useState(null);
    const formik = useFormik({
        initialValues: {
            status: initialStatus,
            rejectReason: ''
        },
        validationSchema: Yup.object({
            status: Yup.string().required("Wartość jest wymagana"),
            rejectReason: Yup.string().when('status', { is: "2", then: Yup.string().required("Wartość jest wymagana") }),
        }),
        onSubmit: (values) => {
            setApiErrors(null);
            values.washId = washId;
            handleChangeStatus(values).then(() => {
                formik.setSubmitting(false);
                handleToggleModal();
            }, (errors) => {
                setApiErrors(errors);
                formik.setSubmitting(false);
            });
        }
    });

    function renderModalContent() {
        return (<form onSubmit={formik.handleSubmit}>
            {isLoading && <AppLoader type="absolute" />}
            <div className="d-flex flex-fill">
                <div className="flex-fill">
                    <div className="row">
                        <div className="form-group col-12">
                            <label htmlFor="status">Status</label>
                            <select
                                autoComplete="status"
                                className={`form-control ${(formik.errors && formik.touched.email && formik.errors.email) || (errors && errors.email) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.status}
                                name="status"
                                id="status">
                                <option value="1" disabled={true}>Do akceptacji</option>
                                <option value="2">Odrzucona</option>
                                <option value="3">Zaakceptowana</option>
                            </select>
                            {formik.errors.status && formik.touched.status ? (
                                <div className="error-warpper">{formik.errors.status}</div>
                            ) : null}
                            {errors && errors.status ? (
                                <div className="error-warpper">{errors.status}</div>
                            ) : null}
                        </div>
                    </div>
                    {formik.values.status == "2" && <div className="row">
                        <div className="form-group col-12">
                            <label htmlFor="rejectReason">Powód odrzucenia</label>
                            <textarea
                                autoComplete="rejectReason"
                                className={`form-control ${(formik.errors && formik.touched.rejectReason && formik.errors.rejectReason) || (errors && errors.rejectReason) ? 'is-error' : ''}`}
                                onChange={formik.handleChange}
                                value={formik.values.rejectReason}
                                name="rejectReason"
                                id="rejectReason"
                                rows="5">
                            </textarea>
                            {formik.errors.rejectReason && formik.touched.rejectReason ? (
                                <div className="error-warpper">{formik.errors.rejectReason}</div>
                            ) : null}
                            {errors && errors.rejectReason ? (
                                <div className="error-warpper">{errors.rejectReason}</div>
                            ) : null}
                        </div>
                    </div>}
                </div>
            </div>
        </form>);
    }

    return (<ModalComponent
        content={renderModalContent()}
        show={show}
        handleSubmit={formik.handleSubmit}
        handleToggleModal={handleToggleModal}
        contentText="Zmiana statusu"
        submitText="Zapisz" />);
}

export default StatusChangeModal;
