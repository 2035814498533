import _ from "lodash";
import { showToast } from '../../application/actions/app.action';
import Api from '../../application/services/api.service';

const namespace = 'WASHES';

export const SET_WASHES = `SET_WASHES_${namespace}`;
export const CLEAR_WASHES = `CLEAR_WASHES_${namespace}`;
export const SET_WASH = `SET_WASH_${namespace}`;
export const CLEAR_WASH = `CLEAR_WASH_${namespace}`;
export const IS_LOADING = `IS_LOADING_${namespace}`;
export const SET_PAGE = `SET_PAGE_${namespace}`;
export const CLEAR_PAGE = `CLEAR_PAGE_${namespace}`;
export const SET_OFFSET = `SET_OFFSET_${namespace}`;

export const setWash = (wash) => ({
    type: SET_WASH,
    wash
});

export const clearWash = () => ({
    type: CLEAR_WASH
});

export const setWashes = (washes, total) => ({
    type: SET_WASHES,
    washes,
    total
});

export const clearWashes = () => ({
    type: CLEAR_WASHES
});

export const isLoading = (isLoading) => ({
    type: IS_LOADING,
    isLoading
});

export function setOffset(offset) {
    return (dispatch) => {
        dispatch({
            type: SET_OFFSET,
            offset: offset
        });
    }
}

export function setPaginationRoute(page) {
    return (dispatch, getState) => {
        const { WashReducer: { limit } } = getState();

        dispatch(setOffset(limit * ((page || 1) - 1)));
        dispatch({
            type: SET_PAGE,
            page: page || 1
        });

        dispatch(getWashes());
        window.scrollTo(0, 0);
    }
}

export function clearPaginationRoute() {
    return dispatch => {
        dispatch(setOffset(0));
        dispatch({
            type: CLEAR_PAGE
        });
        window.scrollTo(0, 0);
    }
}

export function getWash(id) {
    return dispatch => {

        dispatch(isLoading(true));

        return Api.get(`admin/wash/${id}`, null).then((result) => {
            dispatch(isLoading(false));
            if (result.success) {
                dispatch(setWash(result.document));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania myjni'));
            }
        });
    };
}

export function getWashes() {
    return (dispatch, getState) => {

        const { WashReducer: { limit, offset, queryFields, sortOptions }, AppReducer: { searchQuery, sortBy } } = getState();

        let defaultParams = {
            "limit": limit,
            "offset": offset,
            "sort[active]" : -1,
            "sort[status]": 1,
        }

        let defaultSortOption = sortOptions.filter((option) => {
            return option.default;
        })[0];

        let params = Object.assign({}, defaultParams, sortBy ? sortBy.value : defaultSortOption.value);

        if (searchQuery && queryFields) {
            queryFields.split(",").forEach((field) => {
                params[`${field}[orin]`] = searchQuery;
            });
        }

        dispatch(isLoading(true));

        return Api.get('admin/wash', params).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(setWashes(result.documents, result.total));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania myjni'));
            }
        });

    };
}

export function saveWash(data, id, dispatch) {
    return new Promise((resolve, reject) => {
        if (data.owner && _.isObject(data.owner)) {
            data.owner = data.owner.value;
        }
        dispatch(isLoading(true));
        if (id) { // edycja
            return Api.put('admin/wash', data, id, true).then((result) => {
                dispatch(isLoading(false));
                if (result.success) {
                    dispatch(showToast('info', 'Myjnia została zaktualizowana'));
                    return resolve();
                } else if ('errors' in result) {
                    dispatch(showToast('alert', 'Błąd podczas aktualizacji myjni'));
                    return reject(result.errors);
                }
            });
        } else { // dodawanie
            return Api.post('admin/wash', data, true).then((result) => {
                dispatch(isLoading(false));
                if (result.success) {
                    dispatch(showToast('info', 'Myjnia została dodana'));
                    return resolve();
                } else if ('errors' in result) {
                    dispatch(showToast('alert', 'Błąd podczas dodawania myjni'));
                    return reject(result.errors);
                }
            });
        }
    });
}

export const changeIsActive = () => {
    return async (dispatch, getState) => {
        dispatch(isLoading(true));

        const { WashReducer: { wash } } = getState();
        let res = await Api.put("admin/wash", { active: !wash.active }, wash._id);
        dispatch(isLoading(false));
        if (res && res.success) {
            dispatch(showToast('info', 'Zapisano zmiany'));
            dispatch(setWash(Object.assign({}, wash, { active: !wash.active })));
        } else {
            dispatch(showToast('alert', 'Wystąpił błąd'));
        }
    }
}

export function changeStatus(data, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch(isLoading(true));
        data.status = parseInt(data.status);
        return Api.put('admin/changeStatus', data, data.washId).then((result) => {
            dispatch(isLoading(false));
            if (result.success) {
                dispatch(getWash(data.washId));
                dispatch(showToast('info', 'Status został zmieniony'));
                return resolve();
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });

    });
}

export function getPlaces(query, token) {
    return new Promise((resolve, reject) => {
        return Api.get('application/getLocations', {
            query: query,
            token: token
        }).then((result) => {
            if (result.success) {
                return resolve(result.data);
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });
    });
}

export function getPlaceDetails(placeId, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch(isLoading(true));
        return Api.get('application/getLocationDetails', {
            place_id: placeId
        }).then((result) => {
            dispatch(isLoading(false));
            if (result.success) {
                return resolve(result.data);
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });
    });
}

export function getUsers(query, dispatch) {
    return new Promise((resolve, reject) => {
        return Api.get('admin/user', {
            name: query,
            select: "name"
        }).then((result) => {
            if (result.success) {
                return resolve(result.documents.map((user) => { return { name: user.name, value: user._id } }));
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });
    });
}